import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ElementUI from 'element-ui';
import VConsole from 'vconsole';
import Vue from 'vue';

import App from '@/App.vue';
import initIEPlaceholder from '@/directive/placeholder';
import { i18n } from '@/language/index';
import router from '@/router';
import store from '@/store';
import { initHttpInterceptors } from '@/utils/http/index';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/iconfont/iconfont.css';
import '@/assets/font/font.css';
import '@/styles/index.scss';
initIEPlaceholder();

const isProd = process.env.NODE_ENV === 'production';
if (!isProd) {
  const vconsole = new VConsole();
  Vue.use(vconsole);
}
Vue.config.productionTip = false;
initHttpInterceptors(store, router, i18n);
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');

Vue.prototype.$bus = new Vue();

// init gtag
try {
  const { GA_TAG_ID } = require('@/config/env.' + process.env.NODE_ENV);
  const gascript = window.document.createElement('script');
  gascript.setAttribute('async', true);
  gascript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${GA_TAG_ID}`);
  window.document.body.appendChild(gascript);

  window.dataLayer = window.dataLayer || [];
  // eslint-disable-next-line no-inner-declarations
  function gtag() { window.dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', GA_TAG_ID, {
    integration_type: 'WEB',
    // send_page_view: false,
  });
  window.gtag = gtag;

  const getUrlParameter = (n) => {
    try {
      const name = n.replace(/[\\[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      const results = regex.exec(location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    } catch (err) {
      //
    }
    return '';
  };

  let performanceTimer = null;
  const collectPerformanceInfo = () => {
    if (window.performance && window.performance.getEntriesByType) {
      const entries = window.performance.getEntriesByType('navigation');
      if (entries.length > 0) {
        const navTiming = entries[0];
        if (navTiming.loadEventEnd > 0) {
          performanceTimer && clearTimeout(performanceTimer);
          const pageLoadTime = navTiming.loadEventEnd - navTiming.startTime;
          const resourceTiming = navTiming.responseEnd - navTiming.redirectStart;
          const processTiming = navTiming.domComplete - navTiming.domInteractive;
          const redirectEndTiming = navTiming.redirectEnd - navTiming.redirectStart;
          const workerStartTiming = navTiming.workerStart - navTiming.redirectEnd;
          const fetchStartTiming = navTiming.fetchStart - navTiming.workerStart;
          const domainLookupStartTiming = navTiming.domainLookupStart - navTiming.fetchStart;
          const domainLookupEndTiming = navTiming.domainLookupEnd - navTiming.domainLookupStart;
          const connectStartTiming = navTiming.connectStart - navTiming.domainLookupEnd;
          const secureConnectionStartTiming = navTiming.secureConnectionStart - navTiming.connectStart;
          const connectEndTiming = navTiming.connectEnd - navTiming.secureConnectionStart;
          const requestStartTiming = navTiming.requestStart - navTiming.connectEnd;
          const interimResponseStartTiming = navTiming.firstInterimResponseStart - navTiming.requestStart;
          const responseStartTiming = navTiming.responseStart - navTiming.firstInterimResponseStart;
          const responseEndTiming = navTiming.responseEnd - navTiming.responseStart;
          const domContentLoadedEventStartTiming = navTiming.domContentLoadedEventStart - navTiming.domInteractive;
          const domContentLoadedEventEndTiming = navTiming.domContentLoadedEventEnd - navTiming.domContentLoadedEventStart;
          const domCompleteTiming = navTiming.domComplete - navTiming.domContentLoadedEventEnd;
          const loadEventStartTiming = navTiming.loadEventStart - navTiming.domComplete;
          const loadEventEndTiming = navTiming.loadEventEnd - navTiming.loadEventStart;
          const unloadEventEndTiming = navTiming.unloadEventEnd - navTiming.unloadEventStart;
          const ttfbTiming = navTiming.responseStart - navTiming.fetchStart;
          const key = getUrlParameter('key') || '';
          window.gtag('event', 'page_load_time', {
            key,
            new_page_load_time: pageLoadTime,
            new_resource_timing: resourceTiming,
            new_process_timing: processTiming,
            redirectEnd_timing: redirectEndTiming < 0 ? 0 : redirectEndTiming,
            workerStart_timing: workerStartTiming < 0 ? 0 : workerStartTiming,
            fetchStart_timing: fetchStartTiming < 0 ? 0 : fetchStartTiming,
            domainLookupStart_timing: domainLookupStartTiming < 0 ? 0 : domainLookupStartTiming,
            domainLookupEnd_timing: domainLookupEndTiming < 0 ? 0 : domainLookupEndTiming,
            connectStart_timing: connectStartTiming < 0 ? 0 : connectStartTiming,
            secureConnectionStart_timing: secureConnectionStartTiming < 0 ? 0 : secureConnectionStartTiming,
            connectEnd_timing: connectEndTiming < 0 ? 0 : connectEndTiming,
            requestStart_timing: requestStartTiming < 0 ? 0 : requestStartTiming,
            interimResponseStart_timing: interimResponseStartTiming < 0 ? 0 : interimResponseStartTiming,
            responseStart_timing: responseStartTiming < 0 ? 0 : responseStartTiming,
            responseEnd_timing: responseEndTiming < 0 ? 0 : responseEndTiming,
            domContentLoadedEventStart_timing: domContentLoadedEventStartTiming < 0 ? 0 : domContentLoadedEventStartTiming,
            domContentLoadedEventEnd_timing: domContentLoadedEventEndTiming < 0 ? 0 : domContentLoadedEventEndTiming,
            domComplete_timing: domCompleteTiming < 0 ? 0 : domCompleteTiming,
            loadEventStart_timing: loadEventStartTiming,
            loadEventEnd_timing: loadEventEndTiming,
            unloadEventEnd_timing: unloadEventEndTiming,
            ttfb_timing: ttfbTiming,
          });
        } else {
          performanceTimer = setTimeout(collectPerformanceInfo, 200);
        }
      }
    }
  };
  window.addEventListener('load', collectPerformanceInfo);
} catch (error) {}
