import ElementUILocal from 'element-ui/lib/locale/lang/fi';

import country from './country/fi';
import state from './state/fi';

export default {
  message: {
    title: 'Pembayaran',
    powerByOnerway: 'Didukung oleh <span class="ic-powerby"></span>',
    checkout: {
      timeTip: 'Silakan selesaikan pembayaran dalam <span class="time">{time}</span> menit, jika tidak halaman akan kedaluwarsa.',
      billingAddressTip: 'Biaya akan muncul di tagihan Anda sebagai {billingAddress}.',
      cardInfo: 'Informasi Kartu',
      cardNumber: 'Nomor kartu',
      prepaidNumber: 'Nomor kartu',
      summary: 'Ringkasan Pesanan',
      expiredDate: 'Tanggal Kedaluwarsa',
      issueDate: 'Tanggal Terbit',
      cvv: 'CVC/CVV',
      firstName: 'Nama depan',
      lastName: 'Nama belakang',
      holderName: 'Nama pemegang kartu',
      walletAccountName: 'Nama',
      orderno: 'ID Pesanan',
      website: 'Situs Web',
      amount: 'Jumlah',
      pay: 'Bayar',
      payNow: 'Bayar Sekarang',
      update: 'Perbarui',
      deduction: 'Jumlah Tagihan',
      nextDeductionTime: 'Tanggal Penagihan Berikutnya',
      subscriptionEndTime: 'Tanggal Berakhir Langganan',
      frequency: 'Setiap',
      week: 'Minggu',
      day: 'Hari',
      month: 'Bulan',
      year: 'Tahun',
      cancel: 'Batal',
      tip: 'Tips',
      cancelPayTip: 'Apakah Anda ingin membatalkan pembayaran ini?',
      yes: 'Ya',
      no: 'Tidak',
      subscription: 'Langganan',
      schedule: 'Jadwal Pembayaran',
      endTime: 'Tanggal Akhir',
      subscriptionTip: 'Dengan mengonfirmasi pembayaran, Anda mengizinkan pedagang untuk menagih kartu Anda untuk pembayaran di masa mendatang sesuai dengan syarat dan ketentuan mereka.',
      frequencyData: {
        D: 'Sekali setiap {time} hari',
      },
      endTimeData: '{month} {day}, {year}',
      billingAddress: 'Alamat Penagihan/Informasi Kontak',
      country: 'Negara',
      province: 'Provinsi/Negara Bagian/Wilayah',
      address: 'Alamat',
      city: 'Kota',
      postalCode: 'Kode Pos',
      email: 'Email',
      phone: 'Nomor Telepon',
      street: 'Nomor Jalan',
      number: 'Nomor',
      identityNumber: 'Dokumen',
      intallment: 'Cicilan',
      intallmentPlan: 'Rencana Cicilan',
      paymentMethod: 'Metode Pembayaran',
      bankName: 'Nama Bank',
      iBan: 'IBAN',
      contactName: 'Nama Kontak',
      birthDate: 'Tanggal Lahir',
      paymentCompleted: 'Pembayaran selesai',
      wechatPaymentTip: 'Gunakan WeChat untuk memindai kode QR untuk membayar',
      paymentPending: 'Menunggu pembayaran',
      billingCountry: 'Negara Penagihan',
      billingCountryTip: 'Silakan pilih negara penagihan terlebih dahulu, agar kami dapat merekomendasikan metode pembayaran yang sesuai untuk Anda.',
      nextStep: 'Langkah Berikutnya',
      changeCountryTip: 'Setelah mengubah negara, kami akan merekomendasikan metode pembayaran yang sesuai.',
      scanCode: 'Pindai',
      txnTime: 'Waktu Transaksi',
      moreInfo: 'Informasi lebih lanjut',
      applePayNotSupport: 'Perangkat ini tidak mendukung Apple Pay.',
      policy: {
        prefix: 'Saya telah membaca dan menyetujui ',
        suffix: '',
      },
      privacy: 'Kebijakan Privasi',
      intallmentTip: 'Anda mungkin harus membayar bunga untuk cicilan.',
      payInFull: 'Bayar Penuh',
      payIn: 'Bayar dalam {count} kali',
      blikcode: 'Kode BLIK',
      unavailable: 'Tidak Tersedia',
      notSupportPaymentTips: 'Metode pembayaran tidak tersedia saat ini. Silakan kembali ke halaman pilihan.',
      returnText: 'Kembali ke pilihan',
      returnMerchant: 'Kembali ke Halaman Pedagang',
      cvvTips: 'Kode CVC terletak di bagian belakang kartu Anda di sebelah kanan strip tanda tangan putih; selalu 3 digit terakhir untuk Visa dan Mastercard. Untuk American Express, itu adalah 4 digit di bagian depan kartu.',
      holderNameTips: 'Nama lengkap pada kartu',
      continue: 'Lanjutkan',
      validateCVVTitle: 'Verifikasi Keamanan',
      validateCVVDesc: 'Untuk keamanan pembayaran Anda, silakan verifikasi CVC/CVV kartu Anda',
      subscriptionProduct: 'Produk Langganan',
      subscriptionFee: 'Biaya Langganan',
      updateSuccess: 'Pembaruan Berhasil',
      updateFailed: 'Pembaruan Gagal',
      times: 'kali',
    },
    dlocal: {
      amount: 'Jumlah',
      expirationDate: 'Tanggal Kedaluwarsa',
      ticketNumber: 'Nomor Tiket',
      ticketImage: 'Gambar Tiket',
      downloadTicket: 'Unduh Tiket',
      searchForShops: 'Cari Toko',
      fullBoletoView: 'Tampilan Boleto Lengkap',
      back: 'KEMBALI',
      copySuccess: 'Berhasil Disalin',
      errorMsg: 'Waktu habis. Gagal mendapatkan parameter.',
      instructionsTitle: 'Instruksi Pembayaran',
      instructionsContentPIX: 'ID Instruksi Pembayaran:<br/>1. Salin ID transaksi<br/>2. Gunakan ID untuk mengidentifikasi pembayaran PIX Anda di aplikasi perbankan atau baca kode QR di aplikasi bank.',
      instructionsContentRedpagos: 'Anda harus pergi ke toko redpagos untuk melakukan pembayaran.<br/>Dengan memberikan nomor kartu identitas Anda dan menjelaskan bahwa Anda ingin membayar tagihan dlocal, Anda dapat melakukan pembayaran.',
      instructionsContentOXXO: '1. Pergi ke toko oxxo terdekat.<br/>2. Katakan kepada kasir bahwa Anda ingin melakukan pembayaran oxxopay.<br/>3. Berikan nomor referensi di kartu ini kepada kasir untuk diketik.<br/>4. Lakukan pembayaran yang sesuai secara tunai.<br/>5. Setelah pembayaran dikonfirmasi, kasir akan memberi Anda tanda bukti cetak.',
      instructionsContentSPEI: '1. Akreditasi segera.<br/>2. Setelah transfer bank diterima, dlocal akan memberikan produk/layanan yang Anda beli.',
      instructionsContentSencillito: 'Instruksi pembayaran langsung:<br/>Di tempat Sencillito, Anda dapat membayar dengan memberikan nomor referensi dan menyatakan bahwa Anda ingin membayar tagihan DLOCAL.',
      instructionsContentServipag: 'Pertama beritahu kasir kode layanan:<br/>Jika YoPag: "company_id"<br/>Jika di konter:"company_id2"',
      bankDetailsTitle: 'Rincian Bank',
      bankDetailsSPEI: 'Masuk ke perbankan online Anda dan transfer ke akun berikut. Harap dicatat bahwa pembayaran tunai tidak dimungkinkan.',
      paymentTypeTitle: 'Jenis Pembayaran',
      paymentTypeServipag: 'Silakan beri tahu kasir kode layanan<br/>Jika Perusahaan_1: <span class="company">{company1}</span><br/>Jika Perusahaan_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: 'Jumlah',
      orderTips: 'Silakan transfer ke akun berikut',
      numberTitle: 'Nomor Akun (Hanya untuk transaksi ini)',
      copyBtnText: 'Salin',
      expireText: 'pesanan berakhir dalam {time}',
      bankName: 'Nama Bank',
    },
    date: {
      month: {
        1: 'Januari',
        2: 'Februari',
        3: 'Maret',
        4: 'April',
        5: 'Mei',
        6: 'Juni',
        7: 'Juli',
        8: 'Agustus',
        9: 'September',
        10: 'Oktober',
        11: 'November',
        12: 'Desember',
      },
    },
    result: {
      success: 'Pembayaran berhasil',
      failed: 'Pembayaran gagal',
      unknow: 'Sedang membayar',
      unknowTip: 'Silakan segarkan status pesanan untuk melihat hasilnya',
      jump: 'Kembali ke pedagang dalam <span class="hightlight">{time}s</span>',
      reason: 'Alasan',
      processing: 'Sedang diproses',
      timeout: 'Halaman kedaluwarsa, silakan mulai ulang pembayaran',
      invalid: 'Permintaan transaksi tidak valid',
      cancelled: 'Aplikasi pembayaran telah dibatalkan,<br/>silakan jangan ulangi pembayaran',
      emptyPaymentMethod: 'Tidak ada metode pembayaran yang dikonfigurasi untuk negara ini.',
      error: 'Kesalahan. Silakan periksa hasil transaksi di halaman pesanan situs belanja.',
    },
    validator: {
      input: 'Silakan masukkan {field} yang valid',
      intallmentCardOrCountryNotSupport: 'Pembayaran cicilan tidak didukung oleh tipe kartu atau negara!',
    },
    error: {
      timeout: 'Kesalahan jaringan, silakan coba lagi nanti',
      cardNumber: 'Informasi kartu tidak benar',
    },
    dana: {
      validUntil: 'Berlaku hingga',
      placeholderInfo1: 'Silakan transfer ke akun berikut.',
      placeholderInfo2: 'Jumlah transfer harus sama dengan jumlah pesanan agar transaksi berhasil.',
      copyBtn: 'Salin Nomor Akun',
      bankName: 'Nama Bank',
      accountNumber: 'Nomor Rekening (Hanya untuk transaksi ini)',
    },
    country,
    state,
  },
  ...ElementUILocal,
};
