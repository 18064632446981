<template>
  <div class="layout-wrapper" :class="{ 'setting-color': app.brandInfo && app.brandInfo.color }">
    <div class="header-wrapper" v-if="showHeader">
      <div class="header">
        <div class="header-left">
          <template v-if="showLogo">
            <template v-if="app.brandInfo && !app.brandInfo.init">
              <img class="logo" v-if="app.brandInfo.logoImage" :src="app.brandInfo.logoImage" />
              <span class="brand" v-else-if="app.brandInfo.brand">{{ getBrandText(app.brandInfo.brand) }}</span>
              <span class="brand" v-else>{{ getWebsiteText(app.brandInfo.website) }}</span>
            </template>
            <!-- <template v-else>
              <span>显示应用网址</span>
            </template> -->
          </template>
        </div>
        <div class="lang">
          <lang :lang="$route.meta.lang || undefined"></lang>
        </div>
      </div>
    </div>
    <div class="content-wrapper" :style="$route.meta.contentStyle"><router-view v-if="isReload" /></div>
    <div class="footer-box">
      <div class="powerby-box">
        <!-- Powered by <p class="ic-powerby"></p> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import Lang from '@/components/lang/index.vue';
import { subCharByLength } from '@/utils/tools/utils';

export default {
  components: { Lang },
  provide() { // 父组件中通过provide来提供变量，在子组件中通过inject来注入变量
    return {
      reload: this.reload,
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    showLogo() {
      return this.$route.meta.showLogo === undefined ? true : this.$route.meta.showLogo;
    },
    showHeader() {
      return this.$route.meta.showHeader === undefined ? true : this.$route.meta.showHeader;
    },
    ...mapGetters(['app']),
  },
  data() {
    return {
      isReload: true,
    };
  },
  watch: {
    lang: function() {
      this.setDocumentTitle();
    },
  },
  mounted() {
    this.setDocumentTitle();
  },
  methods: {
    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true;
      });
    },
    setDocumentTitle() {
      document.title = this.$t('message.title');
    },
    getBrandText(brand) {
      return subCharByLength(brand, 30);
    },
    getWebsiteText(website) {
      if (!website) {
        return '';
      }
      let w = website.replace('https://', '').replace('http://', '');
      if (w.startsWith('www.')) {
        w = w.replace('www.', '');
      }
      return subCharByLength(w, 30);
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/styles/variables.scss';

.layout-wrapper {
  padding-left: calc(100vw - 100%);
}

.header-wrapper {
  border-bottom: 1px solid $color-border;
  position: fixed;
  top: 0;
  left: calc(100vw - 100%);
  background: #fff;
  width: 100%;
  z-index: 9;
  box-sizing: border-box;

  .header {
    max-width: 1150px;
    display: flex;
    // align-items: flex-start;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 80px;

    .header-left {
      float: left;
      // margin-top: 22px;
    }

    .logo {
      max-width: 50vw;
      height: auto;
      max-height: 52px;
      width: auto;
    }

    .brand {
      max-width: 50vw;
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .lang {
    // width: 120px;
    float: right;
    // margin-top: 30px;
  }
}

.content-wrapper {
  padding-top: 81px;
}

@media screen and (max-width: 1140px) {
  .header-wrapper {
    padding: 0 10px;

    .header {
      height: 60px;
      display: flex;
      align-items: center;

      .header-left {
        margin-top: 0px;
        display: flex;
      }

      .logo {
        max-width: 50vw;
        height: auto;
        max-height: 26px;
        width: auto;
      }

      .brand {
        max-width: 50vw;
        font-size: 13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .lang {
        margin: 10px;
      }
    }
  }

  .content-wrapper {
    padding-bottom: 20px;
    padding-top: 61px;
  }

  .footer-box {
    justify-content: center;
    margin-bottom: 120px;
  }
}
</style>
